import React from "react";
import "./cart.scss";

export const Cart = ({
  cartItems,
  handleAddProduct,
  handleRemoveProduct,
  handelClearCart,
}) => {
  console.log(cartItems, "cart dari kom cart");

  const totalPrice = cartItems.reduce(
    (price, item) => price + item.quantity * item.price,
    0
  );

  return (
    <div className="cont">
      <div className="cart-item">
        <h2 className="cart-header">Cart Item</h2>
        <div className="cart-clear">
          {cartItems.length >= 1 && (
            <button className="claer-btn" onClick={handelClearCart}>
              Claer Cart
            </button>
          )}
        </div>

        {cartItems.length === 0 && (
          <div className="cart-empty"> No Item Added</div>
        )}

        <div>
          {cartItems.map((item) => (
            <div key={item.id} className="cart-list">
              <img
                className="cart-img"
                src={item.thumb}
                alt={item.product_name}
              />
              <div className="cart-name">{item.product_name}</div>
              <div className="cart-func">
                <button
                  className="cart-min"
                  onClick={() => handleRemoveProduct(item)}
                >
                  -
                </button>
                <button
                  className="cart-add"
                  onClick={() => handleAddProduct(item)}
                >
                  +
                </button>
              </div>
              <div className="cart-price">
                {item.quantity} * {item.price}
              </div>
            </div>
          ))}
        </div>

        <div className="cart-total-header">
          Total price
          <div className="cart-total-price">Rp. {totalPrice}</div>
        </div>
      </div>
    </div>
  );
};
