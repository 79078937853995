import { faClock, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <p>Footer</p>
      <div className="social">
        <FontAwesomeIcon className="icon" icon={faClock} />

        <FontAwesomeIcon className="icon" icon={faCoffee} />
      </div>
    </div>
  );
};
