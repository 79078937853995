import React from "react";
import { Route, Switch } from "react-router";
import { Cart } from "../components/cart";
import { Contents } from "../components/contents";

export const Routes = ({
  productItems,
  cartItems,
  handleAddProduct,
  handleRemoveProduct,
  handelClearCart,
}) => {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Contents
            productItems={productItems}
            handleAddProduct={handleAddProduct}
          />
        </Route>

        <Route path="/cart" exact>
          <Cart
            cartItems={cartItems}
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            handelClearCart={handelClearCart}
          />
        </Route>
      </Switch>
    </div>
  );
};
