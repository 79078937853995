import React from "react";
import "./header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping, faPerson } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Header = ({ cartItems }) => {
  return (
    <nav>
      <div className="logo">Logo</div>
      <ul>
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to="/">
          <li>Products</li>
        </Link>
        <Link to="/">
          <li>About</li>
        </Link>
        <Link to="/">
          <li>Contact</li>
        </Link>
      </ul>
      <div className="search">
        <Link to="/signup">
          <FontAwesomeIcon className="icon" icon={faPerson} />
        </Link>
        <Link to="/cart">
          <FontAwesomeIcon className="cart" icon={faBasketShopping} />
          <span className="cartVal">
            {cartItems.length === 0 ? "" : cartItems.length}
          </span>
        </Link>
      </div>
    </nav>
  );
};
