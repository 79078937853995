import React from "react";
import "./contents.scss";

export const Contents = ({ productItems, handleAddProduct }) => {
  console.log(productItems, "ini prop dari apps");

  return (
    <div className="content">
      {/* <h3>Barang</h3> */}
      <div className="maint_content">
        {productItems.map((productItem) => (
          <div className="card" key={productItem.id}>
            <div className="card_img">
              <img src={productItem.thumb} alt="gbr" />
            </div>
            <div className="card_header">
              <h2>{productItem.product_name}</h2>
              <p>{productItem.description}</p>
              <p className="price">
                <span>{productItem.currency}</span>
                {productItem.price}
              </p>
              <button
                className="btn"
                onClick={() => handleAddProduct(productItem)}
              >
                Add to cart
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
