import "./App.scss";
// import { Contents } from "./components/contents";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes/routes";
import { useState } from "react";
import Data from "./helpers/Data";

function App() {
  const { productItems } = Data;
  const [cartItems, setCartItems] = useState([]);
  console.log(cartItems, "ini cart");

  const handleAddProduct = (product) => {
    const ProductExist = cartItems.find((item) => item.id === product.id);
    if (ProductExist) {
      setCartItems(
        cartItems.map((item) =>
          item.id === product.id
            ? { ...ProductExist, quantity: ProductExist + 1 }
            : item
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  };

  const handleRemoveProduct = (product) => {
    const ProductExist = cartItems.find((item) => item.id === product.id);
    if (ProductExist.quantity === 1) {
      setCartItems(cartItems.filter((item) => item.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((item) =>
          item.id === product.id
            ? { ...ProductExist, quantity: ProductExist.quantity - 1 }
            : item
        )
      );
    }
  };

  const handelClearCart = () => {
    setCartItems([]);
  };

  return (
    <div className="container">
      <Router>
        <Header cartItems={cartItems} />
        <Routes
          productItems={productItems}
          cartItems={cartItems}
          handleAddProduct={handleAddProduct}
          handleRemoveProduct={handleRemoveProduct}
          handelClearCart={handelClearCart}
        />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
