const data = {
  productItems: [
    {
      id: 1,
      product_name: "YX1 Wireless Earphones",
      description:
        "Tailor your listening experience with bespoke dynamic drivers from the new YX1 Wireless Earphones. Enjoy incredible high-fidelity sound even in noisy environments with its active noise cancellation feature.",
      price: 59900,
      currency: "Rp",
      thumb: "./image/image-xx59.png",
    },
    {
      id: 2,
      product_name: "Almfamidi",
      description:
        "Enjoy your audio almost anywhere and customize it to your specific tastes with the XX59 headphones. The stylish yet durable versatile wireless headset is a brilliant companion at home or on the move.",
      price: 89900,
      currency: "Rp",
      thumb: "./image/image-xx99-mark-one.png",
    },
    {
      id: 3,
      product_name: "Almfamidi",
      description:
        "As the gold standard for headphones, the classic XX99 Mark I offers detailed and accurate audio reproduction for audiophiles, mixing engineers, and music aficionados alike in studios and on the go.",
      price: 175000,
      currency: "Rp",
      thumb: "./image/image-xx99-mark-two.png",
    },
    {
      id: 4,
      product_name: "Almfamidi",
      description:
        "The new XX99 Mark II headphones is the pinnacle of pristine audio. It redefines your premium headphone experience by reproducing the balanced depth and precision of studio-quality sound.",
      price: 299900,
      currency: "Rp",
      thumb: "./image/image-yx1-earphones.png",
    },
    {
      id: 5,
      product_name: "Almfamidi",
      description:
        "Stream high quality sound wirelessly with minimal to no loss. The ZX7 speaker uses high-end audiophile components that represents the top of the line powered speakers for home or studio use.",
      price: 350000,
      currency: "Rp",
      thumb: "./image/image-zx7.png",
    },
    {
      id: 6,
      product_name: "Almfamidi",
      description:
        "Upgrade your sound system with the all new ZX9 active speaker. It’s a bookshelf speaker system that offers truly wireless connectivity -- creating new possibilities for more pleasing and practical audio setups.",
      price: 450000,
      currency: "Rp",
      thumb: "./image/image-zx9.png",
    },
  ],
};

export default data;
